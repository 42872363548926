// Create preconnect links
const preconnectLinks = [
	{ href: 'https://fonts.googleapis.com' },
	{ href: 'https://fonts.gstatic.com', crossorigin: true },
];

// Font stylesheet definitions
const fontStylesheets = [
	'https://fonts.googleapis.com/css2?family=Tangerine:wght@400;500;700;800&family=Playfair+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap',
	'https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Tinos:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap',
	'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Montserrat:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap',
];

// Add preconnect links
for (const linkData of preconnectLinks) {
	const link = document.createElement('link');
	link.rel = 'preconnect';
	link.href = linkData.href;
	if (linkData.crossorigin) link.crossOrigin = 'anonymous';
	document.head.appendChild(link);
}

// Add font stylesheets with non-blocking loading
for (const href of fontStylesheets) {
	const link = document.createElement('link');
	link.href = href;
	link.rel = 'preload';
	link.as = 'style';
	link.onload = function () {
		this.onload = null;
		this.rel = 'stylesheet';
	};
	document.head.appendChild(link);
}
